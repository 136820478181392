body {
  font-family: 'Lato', sans-serif;
  margin: 0
}

/*Overall theming*/
#root {
  height: 100vh;
  display: flex;
}
.mainApp {
  position: relative;
  display: flex;
  width: 100%;
}

.appContent {
  display: flex;
  width: 100%;
}

.appTray.middle {
  width: 80%;
  margin-left: 0;
}

.appTray.left {
  position: relative;
  top: 0;
  right: 0;
  max-width: 50%;
  min-width: 50px;;
  display: flex;
  height: 100%;
}

.appTray.right {
  position: absolute;
  top: 0;
  right: -10px;
  max-width: 50%;
  min-width: 275px;
}

.networkSelectOverride svg {
  right: 8px;
}
